
































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Card } from '@/types/cms/components';
import { Product } from '@/types/neuhaus-website/cms/pages/products';
import { DataTableHeader } from 'vuetify/types/';
import { getFileObjectByUrl } from '@/utils/filehelper';
import { CloneHelper } from '@/utils/clonehelper';

/**
 * CMSAdminComponentProductList component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'cms-admin-component-product-form-dialog': () => import(
            './CMSAdminComponentProductFormDialog.vue'
        )
    }
})
export default class CMSAdminComponentProductList extends Vue {
    // Prop of type boolean that defined whether the items are editable or not
    @Prop(Boolean) readonly editable: boolean | undefined;

    // array with all productCards
    @Prop({ type: Array as () => Array<Card<Product>> }) readonly productCards: Array<Card<Product>> | undefined;

    // search text
    private searchText = '';

    // the item of the new/edited product
    private editedProductCardItem: Card<Product> = {
        id: -1,
        image: {
            id: -1,
            src: '',
            obj: undefined
        },
        title: 'N/A',
        content: {
            id: -1,
            name: '',
            imageObj: undefined,
            imageUrl: '',
            informations: {
                id: -1,
                rows: []
            }
        },
        backgroundColor: '#00000000',
        color: '#000000FF',
        button: {
            id: 0,
            routeUrl: '/productCards',
            backgroundColor: '#808080FF',
            color: '#000000FF',
            content: 'Anzeigen'
        }
    };

    // Default product item values
    private defaultProductItem: Card<Product> = {
        id: -1,
        image: {
            id: -1,
            src: '',
            obj: undefined
        },
        title: 'N/A',
        content: {
            id: -1,
            name: '',
            imageObj: undefined,
            imageUrl: '',
            informations: {
                id: -1,
                rows: []
            }
        },
        backgroundColor: '#00000000',
        color: '#000000FF',
        button: {
            id: 0,
            routeUrl: '/productCards',
            backgroundColor: '#808080FF',
            color: '#000000FF',
            content: 'Anzeigen'
        }
    }

    // The index of the edited product (-1 = new product)
    private editedProductCardIndex = -1;

    // Boolean that defines, whether the FormDialog will be displayed or not
    private showFormDialog = false;

    // Mode of the current form (CRUD string)
    private formDialogMode = 'create';

    /**
     * @returns Array with all table headers
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get tableHeaders(): Array<DataTableHeader> {
        const headers: Array<DataTableHeader> = [
            {
                text: this.$t('table.headers.id').toString(),
                value: 'id'
            },
            {
                text: this.$t('table.headers.name').toString(),
                value: 'content.name'
            }
        ];

        if (this.editable) {
            headers.push({
                text: this.$t('table.headers.actions').toString(),
                value: 'actions'
            });
        }
        
        return headers;
    }

    /**
     * Opens the ProductFormDialog to create a new product
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private addProduct() {
        const newProduct = CloneHelper.deepCloneObject(this.defaultProductItem);

        let maxCardId = -1;
        let maxCardButtonId = -1;
        let maxProductId = -1;

        if (this.productCards) {
            this.productCards.forEach(card => {
                if (card.id > maxCardId) {
                    maxCardId = card.id;
                }

                if (card.button.id > maxCardButtonId) {
                    maxCardButtonId = card.button.id;
                }

                if (card.content.id > maxProductId) {
                    maxProductId = card.content.id;
                }
            });
        }

        newProduct.id = maxCardId +1;
        newProduct.button.id = maxCardButtonId +1;
        newProduct.content.id = maxProductId +1;
        newProduct.button.routeUrl = '/products/' + newProduct.content.id;

        this.editedProductCardItem = newProduct;
        this.editedProductCardIndex = -1;
        this.formDialogMode = 'create';
        this.showFormDialog = true;
    }

    /**
     * Opens the ProductFormDialog to edit the given product element
     * 
     * @param productItem product item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async editProduct(productItem: Card<Product>) {
        if (this.productCards) {
            const product = CloneHelper.deepCloneObject(productItem);

            if (productItem.content.imageUrl) {
                const imageUrl = process.env.VUE_APP_API_URL + productItem.content.imageUrl;
                const imageExtension = productItem.content.imageUrl.split('.').pop();
                const imageObj = await getFileObjectByUrl(
                    imageUrl, 
                    `product-${productItem.content.id}.${imageExtension}`
                );

                product.content.imageObj = imageObj;
            }

            this.editedProductCardItem = product;
            this.editedProductCardIndex = this.productCards.indexOf(productItem);
            this.formDialogMode = 'update';
            this.showFormDialog = true;
        }
    }

    /**
     * Deletes the given product
     * 
     * @param productItem product item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async deleteProduct(productItem: Card<Product>) {
        if (!this.productCards) {
            return;
        }

        const product = CloneHelper.deepCloneObject(productItem);

        if (productItem.content.imageObj) {
            productItem.content.imageObj
            const imageUrl = process.env.VUE_APP_API_URL + productItem.content.imageUrl;
            const imageObj = await getFileObjectByUrl(imageUrl, productItem.content.name);

            product.content.imageObj = imageObj;
        }

        this.editedProductCardItem = product;
        this.editedProductCardIndex = this.productCards.indexOf(productItem);
        this.formDialogMode = 'delete';
        this.showFormDialog = true;
    }

    /**
     * Updates the edited item in the data table
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private updateEditedProduct(formDialogMode: string, productItemIndex: number, productItem: Card<Product>) {
        if (this.productCards) {
            if (formDialogMode == 'create') {
                this.productCards.push(productItem);
            }
            else if (formDialogMode == 'update') {
                Object.assign(this.productCards[productItemIndex], productItem);
            }
            else if (formDialogMode == 'delete') {
                this.productCards.splice(productItemIndex, 1);
            }

            this.showFormDialog = false;
        }
    }
}
